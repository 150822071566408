<template>
  <v-card max-width="426" class="mx-auto px-3 px-md-6 py-6">
    <h1 class="font-medium-20 gray13--text text-center mb-2">
      {{ $t("auth.registerPage.registerForm.createAccountTitle") }}
    </h1>

    <p class="gray7--text text-center mb-6">
      {{ $t("auth.registerPage.registerForm.enterEmailAndPassword") }}
    </p>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-text-field
        name="username"
        v-model="accountModel.username"
        :rules="emailRules"
        required
        :label="$t('auth.registerPage.registerForm.email')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-5"
      >
      </v-text-field>

      <v-text-field
        name="password"
        v-model="accountModel.password"
        @input="validatePassword"
        @focus="reInitValidation"
        @blur="reInitValidation"
        :rules="passwordRules"
        required
        :type="showPassword ? 'text' : 'password'"
        :label="$t('auth.registerPage.registerForm.password')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-5"
        :class="{ 'mb-2': isFocused || isBlurred }"
      >
        <template v-slot:append>
          <div @click="showPassword = !showPassword" class="cursor-pointer">
            <v-icon size="24" v-if="showPassword">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <div v-if="isFocused || (passwordNotValid && isBlurred)" class="mb-5">
        <div class="font-regular-12 gray12--text mb-1">
          {{ $t("auth.registerPage.registerForm.passwordRegexTitle") }}
        </div>

        <ul class="custom-list">
          <li v-for="item in regexErrors" :key="item.type">
            <v-badge
              :color="item.color"
              dot
              inline
              class="badge-size"
            ></v-badge>

            <span class="font-regular-10 gray9--text">{{ item.message }}</span>
          </li>
        </ul>
      </div>

      <v-text-field
        name="passwordConfirm"
        ref="passwordConfirm"
        v-model="accountModel.confirmPassword"
        :rules="passwordConfirmationRules"
        required
        :type="showPasswordConfirmation ? 'text' : 'password'"
        :label="$t('auth.registerPage.registerForm.confirmPassword')"
        filled
        hide-details="auto"
        class="theme-custom-input mb-4"
      >
        <template v-slot:append>
          <div
            @click="showPasswordConfirmation = !showPasswordConfirmation"
            class="cursor-pointer"
          >
            <v-icon size="24" v-if="showPasswordConfirmation">$eyeShow</v-icon>
            <v-icon size="24" v-else>$eyeHidden</v-icon>
          </div>
        </template>
      </v-text-field>

      <v-checkbox
        name="termsCheckBox"
        v-model="accountModel.termsAndConditionsAccepted"
        :rules="termsAndConditionsRules"
        required
        dense
        :ripple="false"
        off-icon="$emptyCheckbox"
        on-icon="$activeCheckbox"
        hide-details="auto"
        class="mb-6"
      >
        <template v-slot:label>
          <div class="gray8--text font-regular-12">
            {{ $t("auth.registerPage.registerForm.agreeWith") }}
            <a
              target="_blank"
              :href="`${baseUrl}/terms`"
              class="gray8--text font-bold-14"
              @click.stop
            >
              {{ $t("auth.registerPage.registerForm.termsAndConditions") }}
            </a>
            {{ $t("auth.registerPage.registerForm.ofRiverPay") }}
          </div>
        </template>
      </v-checkbox>

      <captcha
        class="mb-8"
        :reload="reloadCaptcha"
        @setCaptchaInfo="setCaptchaInfo"
      />

      <v-btn
        :disabled="!valid"
        color="primary"
        block
        depressed
        height="56"
        :loading="loading"
        type="submit"
      >
        <span class="font-semiBold-14">
          {{ $t("auth.registerPage.registerForm.next") }}
        </span>
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import { AuthService } from "@/services";
import Captcha from "@/components/Auth/Captcha";

export default {
  name: "register-form",
  components: {
    Captcha,
  },
  props: [],
  data() {
    return {
      validInput: true,
      loading: false,
      reloadCaptcha: false,
      valid: false,
      showPassword: false,
      showPasswordConfirmation: false,
      baseUrl: process.env.VUE_APP_PUBLIC_BASE_URL,
      accountModel: {
        username: "",
        password: "",
        confirmPassword: "",
        captchaAnswer: "",
        captchaKey: "",
        termsAndConditionsAccepted: false,
      },
      emailRules: [
        (v) => !!v || this.$t("auth.registerPage.registerForm.emailNotEmpty"),
        (v) =>
          (v && v.length <= 40) ||
          this.$t("auth.registerPage.registerForm.emailMaxLength"),
        (v) =>
          /.+@.+\..+/.test(v) ||
          this.$t("auth.registerPage.registerForm.invalidEmail"),
      ],
      passwordRules: [
        (v) =>
          !!v || this.$t("auth.registerPage.registerForm.passwordNotEmpty"),
        (v) =>
          (v && this.validInput) ||
          this.$t("auth.registerPage.registerForm.invalidPassword"),
        // (v) =>
        //   /^(?=.*?[A-Za-z])(.*)$/.test(v) ||
        //   this.$t("auth.registerPage.registerForm.invalidPassword"),
        // (v) =>
        //   /^(?=.*?[0-9])(.*)$/.test(v) ||
        //   this.$t("auth.registerPage.registerForm.invalidPassword"),
        // (v) =>
        //   /^(?=.*?[!@#$%^&*()\-_=+{};:,<.>])(.*)$/.test(v) ||
        //   this.$t("auth.registerPage.registerForm.invalidPassword"),
        // (v) =>
        //   /^(?=.{8,}$)(.*)$/.test(v) ||
        //   this.$t("auth.registerPage.registerForm.invalidPassword"),
      ],
      termsAndConditionsRules: [
        (v) =>
          !!v ||
          this.$t("auth.registerPage.registerForm.confirmTermsIsRequired"),
      ],
      validationRegex: [],
      matchedCase: [],
      regexErrors: [],
      isFocused: false,
      isBlurred: false,
    };
  },
  computed: {
    passwordConfirmationRules() {
      const rules = [];

      if (!this.accountModel.confirmPassword) {
        const required = (v) =>
          !!v ||
          this.$t("auth.registerPage.registerForm.confirmPasswordNotEmpty");

        rules.push(required);
      }

      if (this.accountModel.password) {
        const match = (v) =>
          (!!v && v) === this.accountModel.password ||
          this.$t("auth.registerPage.registerForm.passwordMustMatch");

        rules.push(match);
      }

      return rules;
    },
    passwordNotValid() {
      return this.matchedCase.find((elem) => !elem.isValid);
    },
  },
  watch: {
    "accountModel.password": "validateFields",
  },
  mounted() {
    this.getPasswordRegex();
  },
  methods: {
    validateFields() {
      this.$refs.form.validate();
    },
    getPasswordRegex() {
      AuthService.passwordRegex()
        .then((res) => {
          this.validationRegex = res.data.data;
        })
        .then(() => {
          this.validationRegex.forEach((elem) => {
            let regex = elem.regex;
            regex = regex.substring(1, regex.length - 1);
            this.matchedCase.push({
              message: elem.message,
              type: elem.type,
              regex: regex,
              color: "gray9",
              isValid: false,
            });
          });
        });
    },
    validatePassword(value) {
      this.regexErrors = [];
      this.validInput = true;
      this.matchedCase.forEach((elem) => {
        if (value.match(elem.regex)) {
          if (this.isFocused) {
            elem.color = "success";
            elem.isValid = true;
          }
          this.regexErrors.push(elem);
        } else {
          if (this.isFocused) {
            elem.color = "gray9";
            elem.isValid = false;
            this.validInput = false;
          }
          this.regexErrors.push(elem);
        }
      });
    },
    reInitValidation(event) {
      if (event.type === "focus") {
        this.isFocused = true;
        this.regexErrors = [...this.matchedCase];
      } else if (event.type === "blur") {
        this.isBlurred = true;
        this.isFocused = false;
        this.validatePassword(this.accountModel.password);
      }
    },
    submit() {
      this.loading = true;
      this.reloadCaptcha = false;
      AuthService.register(this.accountModel)
        .then((res) => {
          localStorage.setItem("otp_token", res.data.data.otpToken);
          this.$router.replace("/auth/register/register-verification");
        })
        .finally(() => {
          this.loading = false;
          this.reloadCaptcha = true;
        });
    },
    setCaptchaInfo(data) {
      this.accountModel.captchaAnswer = data.captchaAnswer;
      this.accountModel.captchaKey = data.captchaKey;
    },
  },
};
</script>

<style scoped lang="scss">
@import "RegisterForm";
</style>
